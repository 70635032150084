// HACK: require CSS bundle (so webpack will process it)
import Config from "./config"
import Vue from "vue"
import VueI18n from "vue-i18n"
import App from "./components/app.vue"

import dayjs from "dayjs"
import "dayjs/locale/fr"
dayjs.extend(require("dayjs/plugin/localizedFormat"))
dayjs.extend(require("dayjs/plugin/isoWeek"))
dayjs.extend(require("dayjs/plugin/weekday"))
dayjs.extend(require("dayjs/plugin/customParseFormat"))
dayjs.locale(Config.locale)

require("./assets/stylesheets/app.css")

Vue.config.productionTip = false
Vue.use(VueI18n)

Config.dealer = false

// HACK: Trello SDK requires jquery to be globally available,
// we also use a Zepto modules instead of jQuery (smaller):
require("zepto/src/zepto")
require("zepto/src/event")
require("zepto/src/ajax")
window.jQuery = window.Zepto

// HACK: load Trello SDK to bootstrap the application:
var script = document.createElement("script")
script.src = "https://api.trello.com/1/client.js?key=" + Config.apiKey
script.onload = function () {
  var vm = new Vue({
    i18n: new VueI18n({
      fallbackLocale: "en",
      locale: Config.locale,
      messages: require("./locales.js").default,
    }),

    render(h) {
      return h(App)
    }
  })
  vm.$mount("#app")
}

document.body.appendChild(script)
